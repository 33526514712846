import React, { useCallback, useMemo } from 'react';
import Select from 'react-select-virtualized';
import './styles/Form.scss';

const Form = (props) => {
  const { formState, setFormState, handleSend, asValues } = props;

  const handleChange = useCallback((evtValue) => {
    if (evtValue?.target?.type === 'checkbox') {
      setFormState(evtValue.target.name, evtValue.target.checked);
    } else {
      setFormState(evtValue.target, { value: evtValue.value, label: evtValue.label });
    }
  }, [setFormState]);

  const handleClickSend = useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    handleSend();
  }, [handleSend]);

  const options = useMemo(() => {
    return asValues.map(values => {
      return {
        value: values[0],
        label: values[0] + ' - ' + values[1]
      }
    });
  }, [asValues]);

  return (
    <form className="form-container">
      <div className="form-group">
        <label htmlFor="as1">AS1</label>
        <Select
          id="as1"
          name="as1"
          options={options}
          value={formState.as1}
          className="select-virtualized"
          getOptionValue={({ value }) => value}
          getOptionLabel={({ label }) => label}
          onChange={value => handleChange({ ...value, target: 'as1' })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="as2">AS2</label>
        <Select
          id="as2"
          name="as2"
          options={options}
          value={formState.as2}
          className="select-virtualized"
          onChange={value => handleChange({ ...value, target: 'as2' })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="returnMultiple">ReturnMultiple</label>
        <input
          name="returnMultiple"
          id="returnMultiple"
          type="checkbox"
          checked={formState.returnMultiple}
          value={formState.returnMultiple}
          onChange={handleChange}
        />
      </div>

      <button onClick={handleClickSend}>Envoyer</button>
    </form>
  )
};

export default Form;