import React from 'react';
import './styles/Layout.scss';

const Layout = (props) => {

  return (
    <div className="layout">
      <table width="640" cellSpacing="0" cellPadding="0" border="0" align="center" style={{ margin: '8px auto 15px auto' }}>
        <tbody>
          <tr>
            <td style={{ backgroundColor: '#efefef', fontFamily: 'Arial,Helvetica,sans-serif', color: '#666', textAlign: 'cente', fontSize: 14, padding: '10px 50px', verticalAlign: 'middle' }}>
              <span style={{ fontWeight: 'bold' }}></span>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="100%" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center">
        <tbody>
          <tr>
            <td align="center">
              <p style={{ margin: '20px 0px', textAlign: 'center' }}>
                <a href="https://mydataball.com/fr" data-linkindex="3" target="_blank" rel="noopener noreferrer">
                  <img data-imagetype="External" src="https://mydataball.com/wp-content/uploads/2021/04/logomdb_bleu-1024x207.png" alt="MyDataBall" width="420"/>
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="640" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center" style={{ margin: '15px auto' }}>
        <tbody>
          <tr>
            <td align="center">
              <table cellSpacing="0" cellPadding="0" border="0" align="center">
                <tbody>
                  <tr>
                    <td align="center" style={{ backgroundImage: "url('https://mydataball.com/wp-content/uploads/2021/06/BandeauHaut2_bleu.png')", backgroundSize: 250, width: 250 }}>
                    </td>
                    <td bgcolor="#307fe2" align="center" valign="center" style={{ width: 390, height: 130 }}>
                      <h2 style={{ margin: '0 12px', fontFamily: 'Arial,Helvetica,sans-serif', fontSize: 18, textAlign: 'cente', fontWeight: 'bold', color: '#fff' }}>
                        CyberThreat
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {props.children}

      <table width="640" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center" style={{ margin: '15px auto' }}>
        <tbody>
          <tr>
            <td align="center">
              <table cellSpacing="0" cellPadding="0" border="0" align="center">
                <tbody>
                  <tr>
                    <td align="center" style={{ backgroundImage: "url('https://mydataball.com/wp-content/uploads/2021/06/BandeauBas2_bleu.png')", backgroundSize: 250, width:250 }}>
                    </td>
                    <td bgcolor="#307fe2" align="center" valign="center" style={{ width: 390, height: 130 }}>
                      <h2 style={{ margin: '0 12px', border: 'none', backgroundColor: 'transparent', fontFamily: 'Arial,Helvetica,sans-serif', fontSize: 16, textAlign: 'center', fontWeight: 'bold', color: '#fff' }}>
                          CyberThreat
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="640" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center" style={{ margin: '30px auto 20px auto' }}>
          <tbody>
              <tr>
                  <td align="center" style={{ fontFamily: 'Arial,Helvetica,sans-serif', fontSize: 15, color: '#343434' }}>
                      <p>
                          Retrouvez-nous sur :
                          &nbsp;&nbsp;&nbsp;
                          <a href="https://www.facebook.com/mydataball" data-linkindex="9" target="_blank" rel="noopener noreferrer"><img data-imagetype="External" src="https://mydataball.com/wp-content/uploads/2021/06/fbsph.png" alt="Facebook" width="45" border="0" style={{ verticalAlign: 'middle', fontFamily: 'Arial,Helvetica,sans-serif', color: '#009de9', fontSize: 12 }}/></a>
                          &nbsp;&nbsp;&nbsp;
                          <a href="https://twitter.com/mydataball" data-linkindex="10" target="_blank" rel="noopener noreferrer"><img data-imagetype="External" src="https://mydataball.com/wp-content/uploads/2021/06/twittersph.png" alt="Twitter" width="45" border="0" style={{ verticalAlign: 'middle', fontFamily: 'Arial,Helvetica,sans-serif', color: '#009de9', fontSize: 12 }}/></a>
                          &nbsp;&nbsp;&nbsp;
                          <a href="https://www.linkedin.com/company/mydataball" data-linkindex="11" target="_blank" rel="noopener noreferrer"><img data-imagetype="External" src="https://mydataball.com/wp-content/uploads/2021/06/linkedinsph.png" alt="LinkedIn" width="45" border="0" style={{ verticalAlign: 'middle', fontFamily: 'Arial,Helvetica,sans-serif', color: '#009de9', fontSize: 12 }}/></a>
                      </p>
                  </td>
              </tr>
          </tbody>
      </table>
      <table width="640" cellSpacing="0" cellPadding="0" border="0" align="center">
          <tbody>
              <tr>
                  <td>
                      <img data-imagetype="External" src="https://mydataball.com/wp-content/uploads/2021/06/hr2-1024x38.png" alt="HR2" height="24" border="0"/>
                  </td>
              </tr>
          </tbody>
      </table>
      <table width="640" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center" style={{ margin: '20px auto 40px auto' }}>
          <tbody>
              <tr>
                  <td align="center" style={{ fontFamily: 'Arial,Helvetica,sans-serif', color: '#666', fontSize: 14, paddingTop: 5 }}>
                      <p style={{ margin: '20px 0', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <img src="./images/r2c.png" alt="R2C System" className="logo" />
                          <img src="./images/unistra.png" alt="UniStra" className="logo" />
                          <img src="./images/montimage.png" alt="Montimage" className="logo" />
                      </p>
                      <br/>
                      <span style={{ fontSize: 14 }}>CARTIMIA - Un produit © depuis 2020</span>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
  )
};

export default Layout;