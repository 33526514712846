import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import './styles/Results.scss';

const Results = (props) => {
  const { results } = props;

  const columns = useMemo(() => {
    return [
      {
        Header: 'Autonomous Systems',
        accessor: 'as',
        Cell: ({ value }) => {
          return value.join(' > ');
        }
      },
      {
        Header: 'Map',
        accessor: 'mapLink',
        Cell: ({ value }) => {
          return (
            <a href={value} target="_blank" rel="noreferrer">ici</a>
          );
        }
      },
      {
        Header: 'Risk',
        accessor: 'risk'
      },
      {
        Header: 'Probability Missing',
        accessor: 'probability_missing'
      },
      {
        Header: 'Number of countries',
        accessor: 'countries'
      },
      {
        Header: 'Topological Jump',
        accessor: 'topologies'
      }
    ]
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    headerGroups
  } = useTable({ columns, data: results || [] });

  if (!results) return null;

  if (results && results.length === 0) {
    return (
      <div>No result found</div>
    );
  }

  return (
    <div className="results">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
};

export default Results;