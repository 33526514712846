import { useEffect } from 'react';
import './styles/App.scss';
import Layout from './components/Layout';
import Form from './components/Form';
import Results from './components/Results';
import { useCallback, useState } from 'react';
import axios from 'axios';
import qs from 'query-string';
import Papa from 'papaparse';

const API_URL = 'https://cyberthreat.mydataball.com/api';
// const API_URL = 'http://127.0.0.1:5000/api';

function App() {
  const [formState, setFormState] = useState({
    as1: "",
    as2: "",
    returnMultiple: false
  });
  const [asValues, setAsValues] = useState([]);
  const [results, setResults] = useState(null);

  useEffect(() => {
    axios.get(`${API_URL}/as_list`)
      .then(resp => {
        setAsValues(Papa.parse(resp.data).data);
      })
      .catch(error => {
        window.alert('Erreur lors de la récupération des AS :\n\n' + error.message);
      });
  }, []);

  const handleChangeFormState = useCallback((name, value) => {
    let formStateOld = { ...formState };
    formStateOld[name] = value;

    setFormState(formStateOld);
  }, [formState]);

  const handleSend = useCallback(() => {
    setResults(null);
    const parameters = qs.stringify({
      ...formState,
      as1: formState.as1.value,
      as2: formState.as2.value
    });

    axios.get(`${API_URL}?${parameters}`)
      .then(resp => {
        setResults(resp.data);
      })
      .catch(error => {
        window.alert('Erreur lors de l\'éxécution de la fonction :\n\n' + error.message);
      });
  }, [formState]);

  return (
    <div className="App">
      <Layout>
        <div>
          <Form formState={formState} setFormState={handleChangeFormState} handleSend={handleSend} asValues={asValues} />
          <Results results={results} />
        </div>
      </Layout>
    </div>
  );
}

export default App;
